
























































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, getCurrentInstance, ref, toRefs, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useAnalysis } from '@/utils/analysis.utils';
import { useRehab } from '@/utils/rehab.utils';
import { usePatient } from '@/utils/patient.utils';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import CompositeCard from '@/components/composite/CompositeCard.vue';
import AnalysisDot from '../analysis/AnalysisDot.vue';
import HomeAnalysisSummary from '@/components/home/HomeAnalysisSummary.vue';
import ChartWalkingContainerCadence from '@/components/charts/walking/containers/ChartWalkingContainerCadence.vue';
import ChartWalkingContainerSymmetry from '@/components/charts/walking/containers/ChartWalkingContainerSymmetry.vue';
// Import type -------------------------------------------------------------------------------------
import type { PropType } from '@vue/composition-api';
import type { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';
import type { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import type { Patient } from '@/plugins/podocore/repositories/patients.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeLatestAnalysis',
  components: {
    CompositeCard,
    AnalysisDot,
    HomeAnalysisSummary,
    ChartWalkingContainerCadence,
    ChartWalkingContainerSymmetry
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    light: {
      type: Boolean
    }
  },
  setup(properties, { root }) {
    const currentComponent = getCurrentInstance();

    const busModule = usePodocoreModule('bus');

    const propertiesReferences = toRefs(properties);

    const requestModule = usePodocoreModule('request');

    const { analysis, scenarioKeyPrefix, isDegraded } = useAnalysis(propertiesReferences.analysis);

    const patientRequest = ref(
      requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/patients/${propertiesReferences.analysis.value.patientCuid}`
      )
    );

    const { data: patient } = usePatient();

    if (!patient.value) {
      patientRequest.value.request();
    }

    const isSuccess = computed(() => {
      return !patientRequest.value?.error && !!patientRequest.value?.data;
    });

    watch(isSuccess, (value) => {
      if (value) {
        patient.value = patientRequest.value.data;
      }
    });

    const editedPatient = ref<Entity<Patient> | undefined>(undefined);

    const { dateFormat, hourFormat } = useI18n();
    const { trackSuccess } = useAnalytics();
    const { getMode } = useRehab();

    const hasConditions = computed(() => {
      return (
        analysis.value?.conditions?.painList.length ||
        analysis.value?.conditions?.walkingAidList.length ||
        !!analysis.value?.conditions?.shoesType
      );
    });

    const conditions = computed(() => {
      if (!hasConditions.value) return;
      return analysis.value?.conditions;
    });

    busModule.useEventSubscriber(busModule.events.patientPatched, ({ payload }) => {
      editedPatient.value = payload.patient;
    });

    const patientName = computed(() => {
      const p = editedPatient.value ?? patient.value;
      // TODO : fix typing in @digitsole/blackburn-entities
      return p?.infos
        ? `${p?.infos?.firstName} ${p?.infos?.lastName}`.toUpperCase()
        : `${root.$t('commons.standards.loading')}...`;
    });

    const isFirstAnalysis = computed(() => currentComponent?.vnode.key == 0);

    let firstAnalysisRequest = ref<any>(null);

    const setupFirstAnalysisRequest = () => {
      firstAnalysisRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${analysis.value.cuid}`
      );
      firstAnalysisRequest.value.request();
    };

    if (isFirstAnalysis.value) {
      setupFirstAnalysisRequest();
    }

    function navigateToAnalysis(analysis: AnalysisEntityCompleted) {
      root.$router.push({
        path: `/patients/${analysis.patientCuid}/result/${analysis.cuid}`
      });
      // Analytics
      // TODO : fix typing in @digitsole/blackburn-entities
      if ((analysis._patient as any).demoCuid)
        trackSuccess(`Demo ${scenarioKeyPrefix.value} analysis`, { value: true });
    }

    return {
      // Values
      conditions,
      patientName,
      scenarioKeyPrefix,
      firstAnalysisRequest,
      // Methods
      navigateToAnalysis,
      getMode,
      // Utils
      dateFormat,
      hourFormat,
      // Flags
      hasConditions,
      isFirstAnalysis,
      isDegraded
    };
  }
});
