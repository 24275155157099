




























































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import HomeWidgets from '@/components/home/HomeWidgets.vue';
import HomeLatestAnalysisList from '@/components/home/HomeLatestAnalysisList.vue';
import HomeGetStarted from '@/components/home/HomeGetStarted.vue';
import HomeWidget from '@/components/home/widgets/HomeWidget.vue';
import HomeWidgetPatients from '@/components/home/widgets/HomeWidgetPatients.vue';
import HomeWidgetOptions from '@/components/home/widgets/HomeWidgetOptions.vue';
import DialogDownloadMobileApp from '@/components/dialogs/DialogDownloadMobileApp.vue';
// Import types ------------------------------------------------------------------------------------
import { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

interface WidgetAction {
  name: string;
  dialog?: DialogId;
  href?: string;
  tracker: string;
}

export interface Widget {
  title: string;
  description: string;
  src: string;
  actions: WidgetAction[];
}

export default defineComponent({
  name: 'PageHome',
  components: {
    Page,
    PageRow,
    HomeWidgets,
    HomeLatestAnalysisList,
    HomeGetStarted,
    HomeWidget,
    HomeWidgetPatients,
    HomeWidgetOptions,
    DialogDownloadMobileApp
  },
  setup() {
    const doctorService = usePodocoreModuleService('doctor');
    const workspacesService = usePodocoreModuleService('workspaces');

    const requestModule = usePodocoreModule('request');

    const { useSaas } = usePodocoreModule('saas');
    const { currentPlanName } = useSaas();

    const doctorFirstName = computed(() => {
      return (doctorService.state.value as any).context.doctor.profile.firstName;
    });

    const stuffWidget: Widget = {
      title: 'home.widgets.your-stuff',
      description: 'home.widgets.your-stuff.desc',
      src: 'dashboard-card-download-app.png',
      actions: [
        {
          name: 'commons.actions.download',
          dialog: 'download-mobile-app',
          tracker: 'Download mobile app'
        }
      ]
    };

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const requestBaseParameters = {
      axios: {
        params: {
          sort: [['createdAt', '-1']],
          limit: 5,
          latestStatus: 'completed'
        }
      }
    };
    const latestAnalysisListRequest = requestModule.useAuthenticatedRequest(
      `${apiConfig.default}/analysis`,
      requestBaseParameters
    );

    onBeforeMount(() => {
      latestAnalysisListRequest.request();
    });
    onBeforeUnmount(() => {
      latestAnalysisListRequest.cancel();
    });

    watch(workspacesService.state, (currentState) => {
      if (currentState.matches('success')) {
        latestAnalysisListRequest.request();
      }
    });

    const hasSolesPairs = computed(() => !!currentWorkspace.value._sumDevices);
    const hasCompletedWorkspace = computed(() => !!currentWorkspace.value.address);
    const hasPatient = computed(() => !!currentWorkspace.value._sumPatients);
    const hasFirstAnalysis = computed(
      () => !!latestAnalysisListRequest.data.value?.docs.filter((analysis: any) => !analysis.demoCuid).length
    );

    const latestAnalysisListRequestLoaded = computed(() => !latestAnalysisListRequest.isPending.value);

    const hasStatusCompleted = computed(() =>
      [hasSolesPairs, hasCompletedWorkspace, hasPatient, hasFirstAnalysis].every((x) => x.value === true)
    );

    return {
      // Values
      doctorFirstName,
      currentPlanName,
      stuffWidget,
      hasSolesPairs,
      hasCompletedWorkspace,
      hasPatient,
      hasFirstAnalysis,
      hasStatusCompleted,
      latestAnalysisListRequest,
      latestAnalysisListRequestLoaded
    };
  }
});
