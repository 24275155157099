


















// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive, computed, watch, toRefs } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsChartInterpretations',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup(properties) {
    const { singleAnalysis: analysis } = useSingleAnalysis();

    const { type } = toRefs(properties);

    const chartsParameters = reactive<any>({
      walking_phases: [],
      gaitline: [],
      propulsion_ratio: [],
      pronation_angles: [],
      foot_progression_angle: [],
      clearance_steppage: []
    });

    const recommendations = computed<any>(() => (analysis.value.metrics as any).recommendations);

    const emptyInterpretations = computed(
      () => !!chartsParameters[type.value].filter((interpretation: string) => !interpretation).length
    );

    const filteredInterpretations = computed(() => {
      return chartsParameters[type.value];
    });

    watch(
      analysis,
      () => {
        chartsParameters.walking_phases = [];
        chartsParameters.gaitline = [];
        chartsParameters.propulsion_ratio = [];
        chartsParameters.pronation_angles = [];
        chartsParameters.foot_progression_angle = [];
        chartsParameters.clearance_steppage = [];
        getParameters();
      },
      { deep: true, immediate: true }
    );

    function getParameters() {
      const _recommendations: any = recommendations.value;
      // Gait line
      if (type.value === 'gaitline') {
        chartsParameters.gaitline.push(
          _recommendations.walking_proportion_loading_time,
          _recommendations.walking_proportion_flatfoot_time,
          _recommendations.walking_proportion_propulsion_time
        );
        getRecommendations('gaitline');
      }
      // Ratio proplusion
      if (type.value === 'propulsion_ratio') {
        chartsParameters.propulsion_ratio.push(_recommendations.walking_propulsion_ratio_corrected);
        getRecommendations('propulsion_ratio');
      }
      // Prono-supination angles
      if (type.value === 'pronation_angles') {
        getRecommendations('pronation_angles');
      }
      // Foot progression angle
      if (type.value === 'foot_progression_angle') {
        chartsParameters.foot_progression_angle.push(
          _recommendations.walking_foot_progression_angle_flat_foot,
          _recommendations.walking_swing_width.global
        );
        getRecommendations('foot_progression_angle');
      }
      // Clearance steppage
      if (type.value === 'clearance_steppage') {
        chartsParameters.clearance_steppage.push(
          _recommendations.walking_minimum_toe_clearance,
          _recommendations.walking_plantar_flexion_angle_foot_in,
          _recommendations.walking_plantar_flexion_angle_foot_out
        );
        getRecommendations('clearance_steppage');
      }
    }

    function getRecommendations(parameter: string) {
      let index = 0;
      for (const data of chartsParameters[parameter]) {
        const global = data?.global;
        const left = data?.left_foot;
        const right = data?.right_foot;

        let text;
        if (left && right && left === right) {
          text = `${getTranslate(data.left_foot, 'symmetry')}`;
        }
        if (left && right && left !== right) {
          text = `${getTranslate(data.left_foot, 'left')} - ${getTranslate(data.right_foot, 'right')}`;
        }
        if (left && !right) {
          text = `${getTranslate(data, 'left')}`;
        }
        if (!left && right) {
          text = `${getTranslate(data, 'right')}`;
        }
        if (!left && !right && global) {
          text = `${getTranslate(data.global, 'globals')}`;
        }

        chartsParameters[parameter][index] = text;

        index++;
      }
      chartsParameters[parameter] = chartsParameters[parameter].filter((x: string | null) => x);
    }

    function getTranslate(code: string, type: string) {
      return i18n.t(`interpretations.${type}.${code}`);
    }
    function breakLine(value: string) {
      return value ? value.replace(/(\r\n|\n)/g, '<br/>') : null;
    }

    return {
      // Values
      chartsParameters,
      emptyInterpretations,
      filteredInterpretations,
      // Methods
      breakLine
    };
  }
});
