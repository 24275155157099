


















// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useI18n } from '@/utils/i18n.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import HomeLatestAnalysis from '@/components/home/HomeLatestAnalysis.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'HomeLatestAnalysisList',
  components: {
    AlertError,
    HomeLatestAnalysis
  },
  props: {
    latestAnalysisListRequest: {
      type: Object,
      required: true
    },
    light: {
      type: Boolean
    }
  },
  setup(properties, { root }) {
    const { dateFormat, hourFormat } = useI18n();
    const { trackSuccess } = useAnalytics();

    const analysisListRequest = properties.latestAnalysisListRequest;

    const hasAnalysisList = computed(() => !isPending && analysisListRequest.data.value?.docs[0]);

    function navigateToAnalysis(analysis: any) {
      root.$router.push({
        path: `patients/${analysis.patientCuid}/result/${analysis.cuid}`
      });
      // Analytics
      if (analysis._patient.demoCuid)
        trackSuccess(`Demo ${analysis._scenario.key.split('_')[0]} analysis`, { value: true });
    }

    const isPending = computed(() => properties.latestAnalysisListRequest.isPending.value);

    return {
      // Requests
      analysisListRequest,
      // Methods
      navigateToAnalysis,
      // Utils
      dateFormat,
      hourFormat,
      trackSuccess,
      // Flags
      hasAnalysisList,
      isPending
    };
  }
});
